import Vue from "vue";
import ExpandedCacheService from "./utils/ExpandedCacheService.js";

Vue.directive("cache-expanded", {
  bind: (el, binding, vnode) => {
    var id = el.id;
    if (id) {
      if (ExpandedCacheService.checkExpanded(id))
        vnode.componentInstance.isExpanded = true;

      vnode.componentInstance.$on("onExpandedChanged", expanded => {
        // console.log(id + ":" + expanded);
        if (expanded)
          ExpandedCacheService.addExpanded(id);
        else
          ExpandedCacheService.removeExpanded(id);
      });
    }
    else {
      throw "cache-expanded指令需要id属性";
    }
  }
});