<template>
  <div class="sidebar">
    <div class="sidebarTop">
      <div class="sidebarTopLeft">
        <img src="@/assets/img/logo/logo1.png" style="height: 100%;"/>
      </div>
      <div class="sidebarTopRight">
        <div class="btnCollapse" @click="onCollapse()">
          <i class="btnIcon icon-arrow_back_ios"></i>
        </div>
      </div>
    </div>
    <div class="sidebarCenter">
      <!-- <div style="width: 100%; height: 5000px;"></div> -->
      <r-tree
        ref="menuTree"
        class="menuTree"
        :data="menuData"
        arrowPos="right"
        :iconTdWidth="30"
        selectedKey="selected"
        expandedKey="expanded"
        :onGettingExtraClass="onGettingExtraClass"
        :onMakingIcon="onMakingIcon"
        :onMakingLabel="onMakingLabel"
        @onNodeClick="onNodeClick"/>
    </div>
  </div>
</template>
<script>
import MainMenuData from "@/base/utils/MainMenuData.js";

function _foreachNodes(nodes, action, parents) {
  if (!nodes || !nodes.length) return;
  window.foreach(nodes, function (node) {
    action(node, parents);
    parents.push(node);
    _foreachNodes(node.children, action, parents);
    parents.pop();
  });
}
function foreachNodes(nodes, action) {
  if (typeof action != "function") return;
  _foreachNodes(nodes, action, []);
}

export default {
  data: function () {
    return {
      menuData: null
      // lastSelectedNode: null
    };
  },
  watch: {
    "$route": function () {
      this.updateSelected();
    }
  },
  methods: {
    updateSelected: function () {
      var curRoute = this.$route;
      foreachNodes(this.menuData, function (node, parents) {
        var selected = false;
        if (curRoute.meta && curRoute.meta.nav && curRoute.meta.nav == node.id)
          selected = true;
        else if (curRoute.query && curRoute.query.from && curRoute.query.from == node.id)
          selected = true;
        else
          selected = false;
        node.selected = selected;
        if (selected) {
          window.foreach(parents, function (parent) {
            parent.expanded = true;
          });
        }
      });
    },
    // 加载菜单数据
    loadMenuData: function () {
      foreachNodes(MainMenuData, function (node) {
        node.selected = false;
        node.expanded = false;
      });
      this.menuData = MainMenuData;
      this.updateSelected();
    },
    // 自定义节点额外类名
    onGettingExtraClass: function (vm) {
      return "menuNode" + vm.level;
    },
    // 自定义节点图标生成
    onMakingIcon: function (h, vm) {
      if (!vm.data || !vm.data.icon) return null;
      return "menuIcon-" + vm.data.id + " icon-" + vm.data.icon;
    },
    // 自定义节点主文本生成
    onMakingLabel: function (h, vm) {
      if (!vm.data) return null;
      if (vm.data.id == "world9") {
        return h("span", [
          h("span", vm.data.name),
          h("span", { class: "new" }, "new")
        ]);
      }
      return vm.data.name || "-";
    },
    // 节点点击事件
    onNodeClick: function (enode) {
      if (enode.hasChildren) {
        enode.data.expanded = !enode.data.expanded;
      }
      else {
        // if (this.lastSelectedNode)
        //   this.lastSelectedNode.data.selected = false;
        // enode.data.selected = true;
        // this.lastSelectedNode = enode;
        if (enode.data.path) {
          if (enode.data.path != this.$route.path)
            this.$router.push({ path: enode.data.path });
          this.$store.commit("collapseSidebar");
        }
        else if (enode.data.to) {
          if (enode.data.to != this.$route.name)
            this.$router.push({ name: enode.data.to });
          this.$store.commit("collapseSidebar");
        }
      }
    },
    // 点击收起Sidebar
    onCollapse: function () {
      this.$store.commit("collapseSidebar");
    }
  },
  mounted: function () {
    this.loadMenuData();
  }
}
</script>
<style lang="less" scoped>
  .sidebar {
    width: 240px;
    height: 100%;
    background-color: white;
    // background-color: #fcfcfc;

    .sidebarTop {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 50px;
      background-color: #f6faf3;
      border-bottom: 1px solid #eff3ee;

      .sidebarTopLeft {
        position: absolute;
        left: 0;
        top: 0;
        right: 50px;
        height: 50px;
      }

      .sidebarTopRight {
        position: absolute;
        right: 0;
        top: 0;
        width: 50px;
        height: 50px;

        .btnCollapse {
          float: right;
          margin-top: 5px;
          width: 24px;
          height: 40px;
          line-height: 40px;
          text-align: center;
          cursor: pointer;

          .btnIcon {
            font-size: 16px;
            vertical-align: middle;
          }
        }
      }
    }

    .sidebarCenter {
      position: absolute;
      left: 0;
      top: 51px;
      width: 100%;
      bottom: 0;
      overflow: auto;

      :deep(.menuTree) {
        .r-tree-node {
          >.r-tree-node-table {
            border-bottom: none;
            
            .r-tree-node-tdarrow {
              line-height: 34px;
            }
          }

          .r-tree-node-childrencon {
            display: block;
            overflow: hidden;
            max-height: 0;
            transition: max-height 0.5s;
          }

          .r-tree-node-childrencon-expanded {
            max-height: 600px;
          }
        }

        .r-tree-node-hovered {
          >.r-tree-node-table {
            background-color: #e7f6f8;
          }
        }

        .r-tree-node-selected {
          >.r-tree-node-table {
            background-color: #d1edf0;
          }
        }

        .menuNode1 {
          >.r-tree-node-table {
            color: black;

            .r-tree-node-tdlabel {
              line-height: 30px;
              font-size: 15px;
            }

            .r-tree-node-tdarrow {
              line-height: 30px;

              .r-tree-node-arrow {
                margin-top: 13px;
              }
            }

            .r-tree-node-tdicon {
              line-height: 30px;

              .r-tree-node-icon {
                display: inline-block;
                margin-top: 4px;
                vertical-align: middle;
                font-size: 18px;
              }
            }
          }
        }

        .menuNode2 {
          color: #404040;

          >.r-tree-node-table {
            .r-tree-node-tdlabel {
              line-height: 28px;
              font-size: 13px;
              text-indent: 10px;
            }
          }
        }

        .menuNode3 {
          color: #404040;

          >.r-tree-node-table {
            .r-tree-node-tdlabel {
              line-height: 28px;
              font-size: 12px;
              color: #606060;
            }
          }
        }

        .menuIcon-home {
          color: #00a0ae;
        }
        .menuIcon-download {
          color: #2a81e6;
        }
        .menuIcon-worldList {
          color: #54b808;
        }
        .menuIcon-modList {
          color: #f34848;
        }
        .menuIcon-resourcePacks {
          color: #d3a200;
        }
        .menuIcon-shaderPacks {
          color: #800bc4;
        }
        .menuIcon-gameVersions {
          color: #2a81e6;
        }
      }
    }
  }
</style>
