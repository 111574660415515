import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    isSidebarExpanded: false
  },
  mutations: {
    expandSidebar (state) {
      state.isSidebarExpanded = true;
    },
    collapseSidebar (state) {
      state.isSidebarExpanded = false;
    }
  }
});

export default store;